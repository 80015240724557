<div
  (documentDropped)="uploadDocuments($event)"
  class="drag-and-drop-container"
  dsoDragAndDropUpload
>
  <input
    (change)="documentBrowseHandler($event)"
    type="file"
    id="document-drop-ref"
    multiple
  />
  <p>{{ "REQUESTS_DETAILS.DRAG_AND_DROP_DOCUMENT" | translate }}</p>
  &nbsp;
  <label for="document-drop-ref" class="document-selector">
    {{ "REQUESTS_DETAILS.SELECT_FILE" | translate }}
  </label>
</div>

@for (document of pendingDocumentList(); track $index) {
  <dso-pending-document
    (unselectDocument)="unselectDocument($event)"
    [currentLanguage]="currentLanguage()!"
    [connectionRequestId]="connectionRequestId()"
    [index]="$index"
    [documentDetails]="document"
  />
}

@for (document of documentList$ | async; track document.id) {
  <dso-uploaded-document
    [currentLanguage]="currentLanguage()!"
    [currentLanguageCulture]="currentLanguageCulture()"
    [connectionRequestId]="connectionRequestId()"
    [refreshDocumentListTrigger$]="refreshDocumentListTrigger$"
    [document]="document"
  />
}
