<div class="dialog">
  <div class="dialog__header">
    <h2 class="dialog__headline">
      {{ "REQUESTS_DETAILS.DOCUMENT_DELETE_DIALOG_TITLE" | translate }}
    </h2>
    <dso-icon-button
      [altText]="'COMMON.CANCEL' | translate"
      mat-dialog-close
      icon="close"
      size="medium"
    />
  </div>

  <mat-dialog-content>
    {{ "REQUESTS_DETAILS.DOCUMENT_DELETE_DIALOG_TEXT" | translate }}
  </mat-dialog-content>

  <div class="dialog__footer">
    <mat-dialog-actions align="end">
      <one-button
        (click)="dialogRef.close(false)"
        size="medium"
        mat-dialog-close
        variant="secondary"
      >
        {{ "COMMON.CANCEL" | translate }}
      </one-button>
      <one-button (click)="dialogRef.close(true)" type="submit" size="medium">
        {{ "REQUESTS_DETAILS.DOCUMENT_DELETE_DIALOG_DELETE" | translate }}
      </one-button>
    </mat-dialog-actions>
  </div>
</div>
