@let _document = document();
<div class="document-container">
  <div class="document-metadata">
    <div class="metadata-row-1">
      <mat-icon
        [attr.aria-label]="'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate"
        class="document-icon dso-icon--small"
        svgIcon="file"
      />
      <span class="document-name">{{ _document.name }}</span>
    </div>

    <div class="metadata-row-2">
      <span>{{
        _document.createdAt | date: "shortDate" : "" : currentLanguageCulture()
      }}</span>
      <span class="dot-separator"></span>
      <span>{{
        _document.createdAt | date: "HH:mm:ss" : "" : currentLanguageCulture()
      }}</span>
      <span class="dot-separator"></span>
      <span>{{ _document.createdByUserFullName }}</span>
      <span class="dot-separator"></span>
      <span>{{ _document.size | fileSize: currentLanguage() }}</span>
    </div>
  </div>

  <div class="document-actions">
    <dso-icon-button
      (click)="openDeleteDocumentDialog()"
      [customIcon]="false"
      [matTooltip]="'REQUESTS_DETAILS.DELETE_DOCUMENT' | translate"
      [altText]="'REQUESTS_DETAILS.DELETE_DOCUMENT' | translate"
      icon="delete"
      size="big"
    />

    <dso-icon-button
      (click)="downloadDocument()"
      [matTooltip]="'REQUESTS_DETAILS.DOWNLOAD_DOCUMENT' | translate"
      [altText]="'REQUESTS_DETAILS.DOWNLOAD_DOCUMENT' | translate"
      icon="download"
      size="big"
    />
  </div>
</div>
