@if (pdfSrc(); as pdfSource) {
  <div [style.height]="calculatedPdfViewerHeight()" class="pdf-viewer-wrapper">
    <pdf-viewer
      (page-rendered)="pageRendered()"
      [ngClass]="{ 'h-100': isPdfViewerRendered() }"
      [src]="pdfSource"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      class="pdf-viewer"
    />
  </div>
  <dso-icon-button
    [request]="currentRequest()"
    class="download-button dso-card"
    icon="download"
    size="medium"
    dsoDownloadRequest
    type="PDF"
  >
    {{ "REQUEST_DETAILS.DOWNLOAD_PDF" | translate }}
  </dso-icon-button>
}
