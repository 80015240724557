@if (documentDetailsWritable(); as documentDetails) {
  <div class="document-container">
    <div class="document-metadata">
      <div class="metadata-row-1">
        <mat-icon
          [attr.aria-label]="
            'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate
          "
          class="document-icon dso-icon--small"
          svgIcon="file"
        />
        <span class="document-name">{{ documentDetails.name }}</span>
      </div>

      <div class="metadata-row-2">
        {{ documentDetails.size | fileSize: currentLanguage() }}
      </div>
      <div class="error-message">
        @for (error of documentDetails.errors; track $index) {
          <div>{{ error }}</div>
        }
      </div>
    </div>

    <div class="document-actions">
      @if (documentDetails.uploadOngoing) {
        <mat-progress-spinner
          [mode]="
            documentDetails.uploadProgress === 0
              ? 'indeterminate'
              : 'determinate'
          "
          [value]="documentDetails.uploadProgress"
          diameter="48"
        />
      } @else {
        <dso-icon-button
          (click)="removeDocumentSelection()"
          [customIcon]="false"
          [matTooltip]="'REQUESTS_DETAILS.UNSELECT_DOCUMENT' | translate"
          [altText]="'REQUESTS_DETAILS.UNSELECT_DOCUMENT' | translate"
          icon="close"
          size="big"
        />

        @if (documentDetails.retryAllowed) {
          <dso-icon-button
            (click)="retryUpload()"
            [customIcon]="false"
            [matTooltip]="'REQUESTS_DETAILS.RETRY_DOCUMENT_UPLOAD' | translate"
            [altText]="'REQUESTS_DETAILS.RETRY_DOCUMENT_UPLOAD' | translate"
            icon="cached"
            size="big"
          />
        }
      }
    </div>
  </div>
}
