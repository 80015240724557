<one-navigation
  (languageChange)="setLanguage($event)"
  (logout)="logout()"
  [items]="navigationItems"
  [productName]="productName"
  [userInfo]="userInfo"
  [supportedLanguages]="supportedLanguages"
  [servicePortalBaseUrl]="servicePortalBaseUrl"
  [currentLang]="selectedLanguage"
/>
