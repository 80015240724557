import { DecimalPipe } from "@angular/common";
import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatIconModule } from "@angular/material/icon";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { ErrorHandlingService } from "@app/core/services";
import {
  ConnectionRequestDto,
  DocumentDto,
} from "src/api/dso-portal/generated/models";

import { DocumentComponent } from "./document/document.component";
import { DocumentsUploadComponent } from "./documents-upload/documents-upload.component";

@Component({
  selector: "dso-documents",
  standalone: true,
  imports: [
    TranslateModule,
    DocumentComponent,
    DocumentsUploadComponent,
    MatIconModule,
  ],
  providers: [DecimalPipe],
  templateUrl: "./documents.component.html",
  styleUrl: "./documents.component.scss",
})
export class DocumentsComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #route = inject(ActivatedRoute);
  readonly #errorHandlingService = inject(ErrorHandlingService);

  readonly currentRequest: WritableSignal<ConnectionRequestDto | undefined> =
    signal(undefined);
  readonly documents: Signal<DocumentDto[]> = computed(() =>
    this.currentRequest()
      ? this.currentRequest()!.documentDetails.documents
      : [],
  );

  public ngOnInit(): void {
    this.#route.parent?.data
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: ({ connectionRequest }) =>
          this.currentRequest.set(connectionRequest),
        error: (error) => {
          this.#errorHandlingService.handleError(error, {
            shouldRedirect: false,
            showErrorSnackbar: true,
          });
        },
      });
  }
}
