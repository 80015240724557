import { registerLocaleData } from "@angular/common";
import localeDE from "@angular/common/locales/de";
import localeEN from "@angular/common/locales/en-GB";
import { bootstrapApplication } from "@angular/platform-browser";
import * as Sentry from "@sentry/angular";

import { AppComponent } from "@app/app.component";

import { appConfig } from "./app";
import { environment } from "./environments";

Sentry.init({
  environment: environment.environment,
  dsn: "https://b9798da7d15be9ea232667f985baad31@sentry.eon.com/204",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  // eslint-disable-next-line no-console
  console.error(err),
);
registerLocaleData(localeDE);
registerLocaleData(localeEN);
