import {
  DestroyRef,
  Directive,
  inject,
  Input,
  HostListener,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import {
  DownloadUrlCreatorService,
  ErrorHandlingService,
} from "@app/core/services";
import { ConnectionRequestDto } from "src/api/dso-portal/generated/models";
import { ConnectionRequestsService } from "src/api/dso-portal/generated/services";

@Directive({
  selector: "[dsoDownloadRequest]",
  standalone: true,
})
export class DownloadRequestDirective {
  @Input({ required: true }) request?: ConnectionRequestDto;
  @Input({ required: true }) type?: "ZIP" | "PDF";

  readonly #connectionRequestsService = inject(ConnectionRequestsService);
  readonly #downloadUrlCreatorService = inject(DownloadUrlCreatorService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #errorHandlingService = inject(ErrorHandlingService);

  @HostListener("click")
  executeDownload(): void {
    if (this.request && this.type) {
      this.#connectionRequestsService
        .downloadConnectionRequest({
          id: this.request.id!,
          type: this.type,
        })
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe({
          next: (zip) =>
            this.#downloadUrlCreatorService.create(
              zip as Blob,
              this.request!.requestId,
              this.type,
            ),
          error: (error) => {
            this.#errorHandlingService.handleError(error, {
              shouldRedirect: false,
              showErrorSnackbar: true,
              msgTranslationIdentifier: "SNACKBAR.ACTION_ERROR_MESSAGE",
            });
          },
        });
    } else {
      this.#errorHandlingService.handleError(new Error("No request present"));
    }
  }
}
